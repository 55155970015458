import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import Glide, { Autoplay, Swipe } from "@glidejs/glide/dist/glide.modular.esm"

const Testimonials = ({ testimonials }) => {
  // Set reference for slider element
  const sliderRef = useRef(null)

  useEffect(() => {
    // Attach slider on mount
    const testimonialGlider = new Glide(sliderRef.current, {
      type: "carousel",
      perView: 1,
      autoplay: 28_000,
      hoverpause: true,
    }).mount({ Autoplay, Swipe })

    // Remove slider on cleanup
    return () => testimonialGlider.destroy()
  }, []) // Empty array ensures that effect is only run on mount

  if (testimonials.length === 0) return null

  return (
    <section className="py-12 bg-gray-100 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div ref={sliderRef} className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul
              className="glide__slides grid grid-flow-col auto-cols-max"
              style={{ display: "grid" }}
            >
              {testimonials.map(testimonial => (
                <Slide key={testimonial.id} {...testimonial} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials

const iTestimonial = PropTypes.exact({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
})

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(iTestimonial),
}

Testimonials.defaultProps = {
  testimonials: [],
}

const Slide = ({ name, text, image }) => (
  <li className="glide__slide flex justify-center items-center">
    <blockquote className="mt-8">
      <div className="max-w-3xl mx-auto text-center font-medium text-gray-900 text-lg md:text-xl lg:text-2xl lg:leading-9">
        <p>
          <span className="font-bold lg:text-4xl">&ldquo;</span>
          {text}
          <span className="font-bold lg:text-4xl">&rdquo;</span>
        </p>
      </div>
      <footer className="mt-8">
        <div className="md:flex md:items-center md:justify-center">
          <div className="text-center md:flex-shrink-0">
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.alt}
              className="mx-auto h-16 w-16 rounded-full border border-b-orange"
            />
          </div>
          <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
            <div className="text-base leading-6 font-medium text-gray-900">
              {name}
            </div>
          </div>
        </div>
      </footer>
    </blockquote>
  </li>
)
