import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage, getSrc } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Hero from "../components/Hero"
import Testimonials from "../components/Testimonials"

import "@glidejs/glide/dist/css/glide.core.min.css"

const IndexPage = () => {
  const { datoCmsHomepage, HcpcLogo, CrestLogo } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHomepage {
        testimonials {
          id
          name
          text
          image {
            alt
            gatsbyImageData(width: 85, height: 85)
          }
        }
      }
      HcpcLogo: file(relativePath: { eq: "logos/hcpc.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            quality: 80
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      CrestLogo: file(relativePath: { eq: "logos/crest_csp.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            quality: 80
            layout: FIXED
            placeholder: NONE
          )
        }
      }
    }
  `)

  const { testimonials } = datoCmsHomepage
  const hcpcLogoImageSrc = getSrc(HcpcLogo)
  const crestLogoImageSrc = getSrc(CrestLogo)

  return (
    <main>
      <Seo
        title="Ascension sports massage, North East mobile sports massage. Reduce your pain and tension and get back to what you love."
        desc="Available at a 15 mile radius surrounding Chester-le-Street, including: Durham, Consett, Gateshead, Whickham, Washington & Houghton-le-spring, Newcastle. A premium service for an affordable price."
      />

      <Hero />

      <section>
        <div className="container pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto">
              <div className="text-center">
                <h2 className="font-semibold tracking-wide uppercase heading text-b-blue">
                  We come to you!
                </h2>
                <p className="mt-1 text-xl font-extrabold sm:text-2xl sm:tracking-tight lg:text-3xl">
                  Mobile North East Sports Massage & Physiotherapy
                </p>
                <p className="mt-1 text-lg font-semibold sm:text-xl sm:tracking-tight lg:text-2xl">
                  (Based out of Chester-le-Street)
                </p>
                <p className="max-w-4xl mt-5 mx-auto sm:text-xl text-gray-500">
                  Available at a 15 mile radius surrounding Chester-le-Street,
                  including: Durham, Consett, Gateshead, Whickham, Washington &
                  Houghton-le-spring. I can travel further upon request but
                  travel charges will be applied.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="treatments">
        <div className="container pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
          <h3 className="heading text-center">
            Relax and let us take care of you with our range of treatments.
          </h3>
          <div className="mt-6 grid grid-cols-2 lg:grid-cols-12 gap-4 lg:mt-8">
            <Link
              to="/treatments#sports-massage"
              className="lg:col-start-3 lg:col-span-4"
            >
              <div className="overflow-hidden">
                <StaticImage
                  src="../assets/images/treatments/massage.jpg"
                  quality={90}
                  width={800}
                  height={800}
                  placeholder="blurred"
                  alt="" // Ignore as decoration only
                  className="transition duration-500 ease transform hover:scale-110"
                />
              </div>
              <h6 className="font-header font-bold text-lg text-center">
                Sports Massage
              </h6>
            </Link>
            <Link
              to="/treatments#physiotherapy"
              className="lg:col-start-7 lg:col-span-4"
            >
              <div className="overflow-hidden">
                <StaticImage
                  src="../assets/images/treatments/physiotherapy.jpg"
                  quality={90}
                  width={800}
                  height={800}
                  placeholder="blurred"
                  alt="" // Ignore as decoration only
                  className="transition duration-500 ease transform hover:scale-110"
                />
              </div>
              <h6 className="font-header font-bold text-lg text-center">
                Physiotherapy
              </h6>
            </Link>
          </div>
        </div>
      </section>

      <Testimonials testimonials={testimonials} />

      <section className="bg-white bg-opacity-25">
        <div className="container py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h3 className="text-xl font-heading font-bold text-center text-b-orange">
            Chartered and registered by:
          </h3>
          <div className="flow-root mt-8 lg:mt-10">
            <div className="-mt-4 mx-auto max-w-md flex flex-wrap justify-between lg:space-x-8">
              <div className="mt-4 flex flex-grow flex-shrink-0 lg:flex-grow-0">
                <img
                  src={hcpcLogoImageSrc}
                  title="Health Care Professions Council (HCPC)"
                  alt="Health Care Professions Council (HCPC)"
                  className="object-none h-38 w-full"
                  loading="lazy"
                  width={150}
                />
              </div>
              <div className="mt-4 flex flex-grow flex-shrink-0 lg:flex-grow-0">
                <img
                  src={crestLogoImageSrc}
                  title="Chartered Society of Physiotherapy (CSP)"
                  alt="Chartered Society of Physiotherapy (CSP)"
                  className="object-none h-38 w-full"
                  loading="lazy"
                  width={150}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default IndexPage
