import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import cn from "classnames"

import Glide, {
  Autoplay,
  Swipe,
  Controls,
} from "@glidejs/glide/dist/glide.modular.esm"

export default function Hero() {
  // Set reference for slider element
  const sliderRef = useRef(null)

  useEffect(() => {
    // Attach slider on mount
    const testimonialGlider = new Glide(sliderRef.current, {
      type: "carousel",
      perView: 1,
      autoplay: 10_000,
      hoverpause: true,
    }).mount({ Autoplay, Swipe, Controls })

    // Remove slider on cleanup
    return () => testimonialGlider.destroy()
  }, []) // Empty array ensures that effect is only run on mount

  const { heroOne, heroTwo, heroThree } = useStaticQuery(graphql`
    query HeroQuery {
      heroOne: file(relativePath: { eq: "hero/reduce-pain.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 3000
            height: 2000
            quality: 90
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      heroTwo: file(relativePath: { eq: "hero/be-your-best.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 3000
            height: 2000
            quality: 90
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      heroThree: file(relativePath: { eq: "hero/personilsed-for-you.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 3000
            height: 2000
            quality: 90
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
  `)

  return (
    <section>
      <div ref={sliderRef} className="glide relative">
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides">
            <HeroSlide
              key={`hero_slider_0`}
              heroImage={heroOne}
              header="Reduce your pain and get back to what you love."
              pre="Welcome"
              linkText="Get in touch"
              linkTo="/contact"
              maxWidthClass="lg:max-w-3xl"
              eager
            />
            <HeroSlide
              key={`hero_slider_1`}
              heroImage={heroTwo}
              header="Be your best"
              sub="Competition packages available"
              linkText="See prices"
              linkTo="/prices"
            />
            <HeroSlide
              key={`hero_slider_2`}
              heroImage={heroThree}
              header="A sports massage personalised for you and your needs."
              linkText="Key Principles"
              linkTo="/key-principles"
              maxWidthClass="lg:max-w-4xl"
            />
          </div>
        </div>

        <div className="absolute w-full top-3 left-0 right-0">
          <div className="container text-right" data-glide-el="controls">
            <button
              className="mr-2 p-2 lg:p-3 bg-white bg-opacity-40"
              aria-label="Previous slide"
              data-glide-dir="<"
            >
              <svg
                viewBox="0 0 477.175 477.175"
                preserveAspectRatio="none"
                className="fill-current text-white w-3 h-3 lg:w-6 lg:h-6"
                height="25"
                width="25"
              >
                <path
                  d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                />
              </svg>
            </button>
            <button
              className="mr-2 p-2 lg:p-3 bg-white bg-opacity-40"
              aria-label="Next slide"
              data-glide-dir=">"
            >
              <svg
                viewBox="0 0 477.175 477.175"
                preserveAspectRatio="none"
                className="fill-current text-white w-3 h-3 lg:w-6 lg:h-6"
                height="25"
                width="25"
              >
                <path
                  d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

const HeroSlide = ({
  heroImage,
  header,
  pre,
  sub,
  linkText,
  linkTo,
  maxWidthClass,
  eager = false,
}) => (
  <div className="glide__slide relative overflow-hidden">
    <GatsbyImage
      image={getImage(heroImage)}
      loading={eager ? "eager" : "lazy"}
      style={{
        top: 0,
        bottom: 0,
        width: "100vw",
        position: "absolute",
      }}
    />

    <div className="h-overlay"></div>

    <div className="container">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 h-hero">
          <div
            className={cn(
              "w-full mt-10 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28",
              maxWidthClass
            )}
          >
            <div>
              {pre && (
                <h4 className="text-glow text-2xl font-header font-semibold text-b-blue">
                  {pre}
                </h4>
              )}
              <h1 className="mb-2 text-glow text-4xl tracking-tight font-header font-semibold text-white md:text-5xl">
                {header}
              </h1>
              {sub && (
                <p className="mb-2 text-glow tracking-tight font-header font-semibold text-white text-xl">
                  {sub}
                </p>
              )}
            </div>
            <div className="mt-4 flex justify-start">
              <div>
                <Link
                  to={linkTo}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-gradiant-orange hover:bg-b-orange-dark md:py-4 md:text-lg md:px-10"
                >
                  {linkText}
                  <svg
                    width="20.84"
                    height="11.401"
                    viewBox="0 0 20.84 11.401"
                    className="ml-2"
                  >
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2px"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.331,8l4.095,4.287m0,0-4.095,4.287m4.095-4.287H3"
                      transform="translate(-2 -6.586)"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="absolute left-0 bottom-0 right-0"
      style={{ height: "300px", overflow: "hidden" }}
    >
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%" }}
      >
        <path
          d="M-263.82,135.69 C204.57,46.88 228.27,174.17 838.88,-95.22 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: "none", fill: "#ffffff" }}
          className="lg:hidden"
        ></path>
        <path
          d="M-14.67,140.63 C281.04,141.61 437.92,-16.28 520.87,6.42 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: "none", fill: "#ffffff" }}
          className="hidden lg:block"
        ></path>
      </svg>
    </div>
  </div>
)
